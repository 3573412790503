<template>
  <descriptive-content
    :title="title"
    :subtitle="subtitle"
    :description="description"
    :layout="layout"
    :is-digital-signage="isDigitalSignage"
    :hide="layout === 'homepage' || hide">
    <div class="image">
      <img
        class="img"
        :src="image"
        @load="onLoad"
        v-if="showImage"
        :style="imageHeight"
      />
    </div>
  </descriptive-content>
</template>

<script>
  import ContentMixin from '../../mixin/content'

  export default {
    props: [
      'hide',
      'layout',
      'category',
      'adjustContentHeight',
      'isDigitalSignage',
    ],
    computed: {
      image() {
        if (this.category) {
          const media = this.category?.media?.links?.find(l => l.rel === 'preview')
          if (media) {
            return media.href
          }
        }
        return this.category?.media?.links[0]?.href
      },
      showImage() {
        return this.image
          && (this.layout !== 'homepage'
            || (this.layout === 'homepage' && !this.$helper.findAttribute(this.category, 'Media')?.inherited))
      }
    },
    data() {
      return {
        imageHeight: {
          //height: '100%'
        }
      }
    },
    methods: {
      onLoad() {
        if (this.adjustContentHeight) {
          let image = document.querySelector('.img')
          let appElement = document.querySelector('#app')
          let navigation = document.querySelector('.navigation')
          if (appElement && navigation) {
            let maxHeight = appElement.clientHeight - (navigation.clientHeight + 248)
            if (image?.height >= maxHeight) {
              this.imageHeight = {
                'height': maxHeight + 'px',
              }
            }
          }
        }
      }
    },
    mixins: [
      ContentMixin
    ],
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .image {
    @apply
      flex
      w-full
      h-full
      items-center
      justify-center
      max-w-full
      max-h-full;
  }

  .img {
    @apply
      mx-auto
      max-w-full
      max-h-full;
  }
</style>